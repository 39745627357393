<template>
  <div class="page-table etutTanim  mainDiv mb-30" id="affix-container">
    <div class="animated fadeInUp card-base baslikBg">Etüt Tanımla</div>
    <el-row :gutter="10" v-loading="loading || ilLoading || ilceLoading"
            :element-loading-text='loading ? $t("src.views.apps.etut.tanimlama.addLoading") : ilLoading ? "İl Listesi Getiriliyor. Lütfen Bekleyiniz." : "İlce Listesi Getiriliyor. Lütfen Bekleyiniz."'
            element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 1)">
      <el-form status-icon :model="etutForm" :rules="rulesEtut" ref="etutForm" class="demo-ruleForm">
        <el-col :lg="18" :md="24" :sm="24" :xs="24">
          <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft pb-10 pt-10">
            <el-row>
              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Firma Adı" prop="musteriID">:
                  <el-select style="width: 100%" size="small" auto-complete="off" v-model="etutForm.musteriID"
                             placeholder="Firma Seçiniz" filterable :filter-methos="filterMethod">
                    <el-option v-for="item in musteriler" aria-autocomplete="none" :key="item.musteriID"
                               :label="item.musteriAdi"
                               :value="item.musteriID">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="16" :md="16" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Proje Adı" prop="projeBaslik">:
                  <el-input auto-complete="off" size="small" v-model="etutForm.projeBaslik"
                            :placeholder="'Proje Adı Giriniz'"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Alan" prop="alan">:
                  <el-input auto-complete="off" size="small" @input="formatAlan" v-model="etutForm.alan"
                            :placeholder="'Alan Giriniz'"></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Ada" prop="ada">:
                  <el-input auto-complete="off" size="small" v-mask="'##############'" v-model="etutForm.ada"
                            :placeholder="'Ada Giriniz'"></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Parsel" prop="parsel">:
                  <el-input auto-complete="off" size="small" @input="validateParsel" v-model="etutForm.parsel"
                            :placeholder="'Parsel Giriniz'"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Ülke" prop="ulkeID">:
                  <el-select style="width: 100%" size="small" v-model="etutForm.ulkeID"
                             @change="getIlListesi" placeholder="Ülke Seçiniz" filterable
                             :filter-methos="filterMethod">
                    <el-option v-for="item in ulkeSehir" aria-autocomplete="none" :key="item.ulkeID"
                               :label="item.ulkeAdi"
                               :value="item.ulkeID">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                <el-form-item label="İl" prop="ilID">:
                  <el-select style="width: 100%" size="small" v-model="etutForm.ilID"
                             @change="getIlceListesi" placeholder="İl Seçiniz" filterable
                             :filter-methos="filterMethod">
                    <el-option v-for="item in iller" aria-autocomplete="none" :key="item.ilID" :label="item.ilBaslik"
                               :value="item.ilID">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                <el-form-item label="İlçe" prop="ilceID">:
                  <el-select style="width: 100%" size="small" v-model="etutForm.ilceID"
                             placeholder="İlçe Seçiniz" filterable :filter-methos="filterMethod">
                    <el-option v-for="item in ilceler" aria-autocomplete="none" :key="item.ilceID"
                               :label="item.ilceBaslik"
                               :value="item.ilceID">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Adres" prop="adresBaslik">:
                  <el-input type="textarea" auto-complete="off" :autosize="{ minRows: 2, maxRows: 4 }"
                            placeholder="Adres Giriniz" v-model="etutForm.adresBaslik">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-collapse class="animated fadeInUp" style="margin-top: 10px" v-model="activeCollapse">
            <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.sozlesme.liste.table.sozlesmeAdi")'
                              name="6">
              <div style="padding: 0 10px 0 10px" class="kapsamlar sozlesmeSelectEtut"
                   v-if="gruplanmisSozlesmeler.length > 0">
                <el-collapse-item v-for="(grup, grupIndex) in gruplanmisSozlesmeler" :key="grupIndex"
                                  :name="grup.sozlesmeKategoriID" :title="grup.sozlesmeKategoriAdi">
                  <el-row>
                    <el-col :span="24" class="flex-box" style="padding: 0 5px 0 3px; height: 20px; margin-top: -5px;">
                      <el-form-item>
                        <div style="display: flex; align-items: center;">
                          <span style="margin-right: 5px;">Tümünü Seç</span>
                          <el-switch v-model="grup.grupSwitchState" @change="grupTumunuSec(grup,$event)"
                                     active-color="#13ce66" inactive-color="#ff4949" active-value="1"
                                     inactive-value="0" style="text-align: right !important;"></el-switch>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row v-if="isDesktop && sozlesmeTarihBaslikGoster"
                          style="background-color: #f1f7fc !important; color: #00385a !important;"> <!--e2f1fc-->
                    <el-col :lg="7" :md="7" :sm="7" :xs="7">
                      <b>Sözleşme Kapsamları</b>
                    </el-col>
                    <el-col :lg="2" :md="2" :sm="2" :xs="2">
                      <div style="text-align:center"><b>Yüzde <br> Değer </b></div>
                    </el-col>
                    <el-col :lg="2" :md="2" :sm="2" :xs="2">
                      <div style="text-align:center"><b>Başlama <br> Süresi </b></div>
                    </el-col>
                    <el-col :lg="2" :md="2" :sm="2" :xs="2">
                      <div style="text-align:center"><b>İş <br> Süresi </b></div>
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="3" :xs="3">
                      <div style="text-align:center"><b>Başlama <br> Tarihi</b></div>
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="3" :xs="3">
                      <div style="text-align:center"><b>Tamamlanma <br> Tarihi</b></div>
                    </el-col>
                  </el-row>
                  <el-row v-if="isDesktop" v-for="(sozlesme, sozlesmeIndex) in grup.sozlesmeler" :key="sozlesmeIndex"
                          :style="{backgroundColor:sozlesmeIndex % 2 ?'#f3f7fc':''}">
                    <el-col :lg="24" :md="24" :sm="24" :xs="24">
                      <el-col :lg="7" :md="7" :sm="7" :xs="7">
                        {{ sozlesme.sozlesmeBaslik }}
                      </el-col>
                      <el-col :lg="2" :md="2" :sm="2" :xs="2"
                              style="padding: 0 2px 0 2px;">
                        <el-tooltip v-if="sozlesme.secili == '1'" content='Yüzde Değer' :open-delay="250"
                                    placement="bottom">
                          <el-input @input="sozlesme.yuzdeDeger = formatYuzdeDeger(sozlesme.yuzdeDeger)"
                                    v-model="sozlesme.yuzdeDeger" size="mini"></el-input>
                        </el-tooltip>
                        <div v-else>&nbsp;</div>
                      </el-col>
                      <el-col :lg="2" :md="2" :sm="2" :xs="2"
                              style="padding: 0 2px 0 2px">
                        <el-tooltip v-if="sozlesme.secili == '1'" content='Başlama Süresi' :open-delay="250"
                                    placement="bottom">
                          <el-input :input="sozlesmeSec(sozlesme)" v-mask="'#####'" v-model="sozlesme.baslangicSuresi"
                                    size="mini"></el-input>
                        </el-tooltip>
                        <div v-else>&nbsp;</div>
                      </el-col>
                      <el-col :lg="2" :md="3" :sm="3" :xs="3"
                              style="padding: 0 2px 0 2px">
                        <el-tooltip v-if="sozlesme.secili == '1'" content='İş Süresi' :open-delay="250"
                                    placement="bottom">
                          <el-input :input="sozlesmeSec(sozlesme)" v-mask="'#####'" v-model="sozlesme.isSuresi"
                                    size="mini"></el-input>
                        </el-tooltip>
                        <div v-else>&nbsp;</div>
                      </el-col>
                      <el-col :lg="3" :md="3" :sm="3" :xs="3"
                              style="padding: 0 2px 0 2px; height: 25px !important;">
                        <el-form-item v-if="sozlesme.secili == '1'" prop="sozlesmeBaslamaTarihi">
                          <el-tooltip :disabled="true" content='Sözleşme Başlama Süresi' :open-delay="250"
                                      placement="bottom">
                            <el-date-picker
                                style="position:relative; bottom: 2px"
                                v-model="sozlesme.baslamaTarihi"
                                size="mini" class="select-wide" type="date"
                                :placeholder='"Tarih Seçiniz"'
                                format="dd.MM.yyyy"
                                value-format="yyyy-MM-dd"
                                @input="baslamaSuresiDegistir($event, sozlesme)"
                                :picker-options="sozlesmeDatePickerOptions()"
                            >
                            </el-date-picker>
                          </el-tooltip>
                        </el-form-item>
                        <div v-else>&nbsp;</div>
                      </el-col>
                      <el-col :lg="3" :md="3" :sm="3" :xs="3"
                              style="padding: 0 2px 0 2px; height: 25px !important;">
                        <el-form-item v-if="sozlesme.secili == '1'" prop="sozlesmeBitisTarihi">
                          <el-tooltip :disabled="true" content='Sözleşme Bitiş Tarihi' :open-delay="250"
                                      placement="bottom">
                            <el-date-picker
                                style="position:relative; bottom: 2px"
                                v-model="sozlesme.isTeslimTarihi"
                                size="mini" class="select-wide" type="date"
                                :placeholder='"Tarih Seçiniz"'
                                format="dd.MM.yyyy"
                                value-format="yyyy-MM-dd"
                                @input="bitisSuresiDegistir($event, sozlesme)"
                                :picker-options="sozlesmeDatePickerOptions(sozlesme.baslamaTarihi)"
                            >
                            </el-date-picker>
                          </el-tooltip>
                        </el-form-item>
                        <div v-else>&nbsp;</div>
                      </el-col>
                      <el-col :lg="2" :md="2" :sm="2" :xs="2">
                        <el-tooltip
                            v-if="sozlesme.secili == '1' && allSubArraysEmpty && cakismaIDs.includes(sozlesme.sozlesmeID)"
                            content="Bu tarihlerle kesişen proje bulunmaktadır." :open-delay="500" placement="top">
                          <el-button
                              v-if="sozlesme.secili == '1' && allSubArraysEmpty  && cakismaIDs.includes(sozlesme.sozlesmeID)"
                              @click="open(sozlesme.sozlesmeID)" type="text"
                              style="color: rgb(212, 5, 5); margin-left: 65px !important; margin-top: -10px;"
                              icon="mdi mdi-alert mdi-24px">
                          </el-button>
                        </el-tooltip>
                        <div v-else>&nbsp;</div>
                      </el-col>
                      <el-col :lg="2" :md="2" :sm="2" :xs="2">
                        <el-switch v-model="sozlesme.secili" active-color="#13ce66" inactive-color="#ff4949"
                                   active-value="1" inactive-value="0"
                                   @change="atama(sozlesme, sozlesme.secili)"
                                   style="float: right;"></el-switch>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-row v-if="!isDesktop" v-for="(sozlesme, sozlesmeIndex) in grup.sozlesmeler"
                          :key="sozlesmeIndex * 3">
                    <el-row v-if="!isDesktop" v-for="(sozlesme, sozlesmeIndex) in grup.sozlesmeler"
                            :key="sozlesmeIndex" :style="{backgroundColor:sozlesmeIndex % 2 ?'#f3f7fc':''}">
                      <el-col :span="20">
                        <el-row style="padding:0!important;">
                          <el-col :lg="24" :md="24" :sm="24" :xs="24"
                                  style="place-content: start;place-items: center;display: flex">
                            {{ sozlesme.sozlesmeBaslik }}
                            <el-tooltip content="Bu tarihlerle kesişen proje bulunmaktadır."
                                        v-if="sozlesme.secili == '1' && allSubArraysEmpty && cakismaIDs.includes(sozlesme.sozlesmeID)"
                                        :open-delay="500" placement="top">
                              <el-button
                                  v-if="sozlesme.secili == '1' && allSubArraysEmpty  && cakismaIDs.includes(sozlesme.sozlesmeID)"
                                  @click="open(sozlesme.sozlesmeID)" type="text"
                                  style="color: rgb(212, 5, 5); margin-left: 10px"
                                  icon="mdi mdi-alert mdi-24px">
                              </el-button>
                            </el-tooltip>
                            <!-- <label v-if="allSubArraysEmpty && cakismaIDs.includes(sozlesme.sozlesmeID)" style="color: red;">{{ cakisma }}</label>-->
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="4">
                        <!--                        :key="count[sozlesme.sozlesmeID]"-->
                        <div style="text-align:center;">
                          <el-switch v-model="sozlesme.secili" active-color="#13ce66"
                                     inactive-color="#ff4949" active-value="1" inactive-value="0"
                                     @change="atama(sozlesme, sozlesme.secili)"
                                     style="text-align: right !important;"/>
                        </div>
                      </el-col>
                      <el-col :span="24">
                        <el-row>
                          <el-col v-if="sozlesme.secili == '1'" :span="8">
                            <el-form-item label="Yüzde Değer" class="m-5">
                              <el-input @input="sozlesme.yuzdeDeger = formatYuzdeDeger(sozlesme.yuzdeDeger)"
                                        auto-complete="off" v-model="sozlesme.yuzdeDeger" size="mini"/>
                            </el-form-item>
                          </el-col>
                          <el-col v-if="sozlesme.secili == '1'" :span="9">
                            <el-form-item label="Başlama Süresi" class="m-5">
                              <el-input :input="sozlesmeSec(sozlesme)" auto-complete="off" v-mask="'#####'"
                                        v-model="sozlesme.baslangicSuresi" size="mini"></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col v-if="sozlesme.secili == '1'" :span="7">
                            <el-form-item label="İş Süresi" class="m-5">
                              <el-input v-mask="'#####'" auto-complete="off"
                                        v-model="sozlesme.isSuresi" size="mini"></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col v-if="sozlesme.secili == '1'" :lg="12" :md="12" :sm="12" :xs="12"
                                  style="padding: 0 2px 0 2px; height: 25px !important;">
                            <el-form-item label="Başlama Tarihi" prop="sozlesmeBaslamaTarihi">
                              <el-date-picker
                                  v-model="sozlesme.baslamaTarihi" size="mini" class="select-wide"
                                  type="date" :placeholder='"Tarih Seçiniz"' format="dd.MM.yyyy"
                                  value-format="yyyy-MM-dd" :picker-options="pickerOptions"
                                  @input="baslamaSuresiDegistir($event, sozlesme)">
                              </el-date-picker>
                            </el-form-item>
                          </el-col>
                          <el-col v-if="sozlesme.secili == '1'" :lg="12" :md="12" :sm="12" :xs="12"
                                  style="padding: 0 2px 0 2px; height: 25px !important;">
                            <el-form-item label="Tamamlanma Tarihi" prop="sozlesmeBitisTarihi">
                              <el-date-picker
                                  v-model="sozlesme.isTeslimTarihi" size="mini"
                                  class="select-wide" type="date" :placeholder='"Tarih Seçiniz"'
                                  format="dd.MM.yyyy" value-format="yyyy-MM-dd"
                                  @input="bitisSuresiDegistir($event, sozlesme)"
                                  :picker-options="datePickerOptions(sozlesme.baslamaTarihi)">
                              </el-date-picker>
                            </el-form-item>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-row>
                </el-collapse-item>
              </div>
            </el-collapse-item>
          </el-collapse>
          <el-collapse v-if="false" class="animated fadeInUp" style="margin-top: 10px" v-model="activeCollapse">
            <div style="padding: 0 10px 0 10px" class="sozlesmeSelectEtut" v-if="sozlesmeList.length > 0">
              <el-table :data="gruplanmisSozlesmeler[0].sozlesmeler" :key="sozlesmeKey" style="width: 100%">
                <el-table-column label="Sözleşme Kapsamları (BG Proje)" width="250">
                  <template slot-scope="scope">
                    {{ scope.row.sozlesmeBaslik }}
                  </template>
                </el-table-column>
                <el-table-column v-if="sozlesmeTarihBaslikGoster" label="Yüzde Değer" width="130">
                  <template slot-scope="scope">
                    <el-input @input="scope.row.yuzdeDeger = formatYuzdeDeger(scope.row.yuzdeDeger)"
                              v-model="scope.row.yuzdeDeger" size="mini" v-if="scope.row.secili"></el-input>
                  </template>
                </el-table-column>
                <el-table-column v-if="sozlesmeTarihBaslikGoster" label="Başlama Süresi" width="130">
                  <template slot-scope="scope">
                    <el-input :input="sozlesmeSec(scope.row)" v-mask="'#####'" v-model="scope.row.baslangicSuresi"
                              size="mini" v-if="scope.row.secili"></el-input>
                  </template>
                </el-table-column>
                <el-table-column v-if="sozlesmeTarihBaslikGoster" label="İş Süresi" width="130">
                  <template slot-scope="scope">
                    <el-input :input="sozlesmeSec(scope.row)" v-mask="'#####'" v-model="scope.row.isSuresi"
                              size="mini"></el-input>
                  </template>
                  <template slot-scope="scope">
                    <el-input :input="sozlesmeSec(scope.row)" v-mask="'#####'" v-model="scope.row.isSuresi" size="mini"
                              v-if="scope.row.secili"></el-input>
                  </template>
                </el-table-column>
                <el-table-column v-if="sozlesmeTarihBaslikGoster" label="Başlama Tarihi" width="170">
                  <template slot-scope="scope">
                    <div style="display: flex; align-items: center; margin-top: 7px;">
                      <el-form-item prop="sozlesmeBaslamaTarihi" style="margin-bottom: 0;">
                        <el-tooltip content="Sözleşme Başlama Tarihi" :open-delay="250" placement="bottom">
                          <el-date-picker v-if="scope.row.secili"
                                          style="position:relative; bottom: 4px"
                                          v-model="scope.row.baslamaTarihi"
                                          size="mini" class="select-wide" type="date"
                                          :placeholder="'Tarih Seçiniz'"
                                          format="yyyy-MM-dd"
                                          value-format="yyyy-MM-dd"
                                          @change="baslamaSuresiDegistir($event, scope.row)"
                                          :picker-options="pickerOptions">
                          </el-date-picker>
                        </el-tooltip>
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column v-if="sozlesmeTarihBaslikGoster" label="Tamamlanma Tarihi" width="170">
                  <template slot-scope="scope">
                    <div style="display: flex; align-items: center; margin-top: 7px;">
                      <el-form-item prop="sozlesmeBitisTarihi" style="margin-bottom: 0;">
                        <el-tooltip content="Sözleşme Bitiş Tarihi" :open-delay="250" placement="bottom">
                          <el-date-picker v-if="scope.row.secili"
                                          style="position:relative; bottom: 4px"
                                          v-model="scope.row.isTeslimTarihi"
                                          size="mini" class="select-wide" type="date"
                                          :placeholder="'Tarih Seçiniz'"
                                          format="yyyy-MM-dd"
                                          value-format="yyyy-MM-dd"
                                          @change="bitisSuresiDegistir($event, scope.row)"
                                          :picker-options="sozlesmeDatePickerOptions(scope.row.baslamaTarihi)">
                          </el-date-picker>
                        </el-tooltip>
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column>
                  <template slot="header">
                    <div style="display: flex; justify-content: flex-end;">
                      <el-checkbox v-model="tumunuSec" @change="tumunuSecChange"></el-checkbox>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div style="text-align: right;">
                      <el-checkbox true-label="1" false-label="2" v-model="scope.row.secili"
                                   @change="mobileCheckClick(scope.row,$event),sozlesmeSec(scope.row,$event); sozlesmeKey++"></el-checkbox>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-collapse>
        </el-col>
        <el-col :lg="6" :md="24" :sm="24" :xs="24">
          <el-collapse class="yanForm animated fadeInUp" v-model="activeCollapse">
            <el-collapse-item class="card-shadow--medium"
                              :title='$t("src.views.apps.etut.tanimlama.tarih")' name="10">
              <el-row :gutter="5" style="padding: 0 15px 0 15px">
                <el-col :lg="12" :md="12" :sm="12" :xs="12" class="col-p">
                  <el-form-item :label='$t("src.views.apps.etut.tanimlama.baslamaTarihi")'
                                prop="baslangicTarihi">
                    <el-date-picker size="small" class="select-wide" type="date"
                                    :placeholder='"Tarih Seçiniz"' :picker-options="datePickerOptions()"
                                    v-model="etutForm.baslangicTarihi" format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :lg="12" :md="12" :sm="12" :xs="12" class="col-p">
                  <el-form-item :label='$t("src.views.apps.etut.tanimlama.bitisTarihi")'
                                prop="bitisTarihi">:
                    <el-date-picker size="small" class="select-wide" type="date"
                                    :placeholder='"Tarih Seçiniz"'
                                    :picker-options="datePickerOptions(etutForm.baslangicTarihi)"
                                    v-model="etutForm.bitisTarihi" format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-collapse-item>
            <el-collapse-item class="card-shadow--medium pt-10" name="8"
                              :title='$t("src.views.apps.genel.durum")'>
              <el-row>
                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                  <el-form-item prop="durum">
                    <el-select size="small" :disabled="disabled" v-model="etutForm.durum"
                               :placeholder='$t("src.views.apps.messages.addBultenTitle")' style="width: 100%">
                      <el-option value="0" :label='$t("src.views.apps.durum.etut.onay")'></el-option>
                      <el-option value="1"
                                 :label='$t("src.views.apps.durum.etut.aktif")'></el-option>
                      <el-option value="2"
                                 :label='$t("src.views.apps.durum.etut.askiyaAl")'></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-button size="mini" v-on:click="etutEkle('etutForm', etutForm.durum)" class="onayBtn"
                           type="primary">
                  <label>Kaydet</label>
                </el-button>
                &nbsp;
                <el-popover placement="bottom" width="300" v-model="visibleSil">
                  <p>{{ $t('src.views.apps.messages.clearFormAlert') }}</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="primary" @click="resetForm('etutForm')">
                      {{ $t('src.views.apps.genel.yes') }}
                    </el-button>
                    <el-button size="mini" @click="visibleSil = false;">
                      {{ $t('src.views.apps.genel.no') }}
                    </el-button>
                  </div>
                  <el-button size="mini" slot="reference">
                    {{ $t('src.views.apps.genel.temizle') }}
                  </el-button>
                </el-popover>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-form>
    </el-row>
    <cakisma-dialog v-if="dialogVisibleYonetici" :dialogvisible.sync="dialogVisibleYonetici"
                    :userdata="userDataBGSurec"/>
  </div>
</template>
<script>
import ClassicEditor from '../../../../node_modules/ckeditor5-build-classic';
import haberService from '../../../WSProvider/HaberService'
import musteriService from '../../../WSProvider/MusteriService'
import projeService from '../../../WSProvider/ProjeService'
import sozlesmeService from '../../../WSProvider/SozlesmeService'
import notification from '../../../notification'
import functions from '../../../functions'
import EventBus from '@/components/event-bus'
import CakismaDialog from '@/components/Cakisma'
import etutService from '../../../WSProvider/EtutService';

var moment = require('moment');
let time = moment().format('YYYY-MM-DD');

export default {
  name: "EtutTanimla",
  components: {
    CakismaDialog
  },
  watch: {
    seciliSozlesmeler(newVal) {
      if (newVal.length > 0) {
        this.sozlesmeTarihBaslikGoster = true
      } else {
        this.sozlesmeTarihBaslikGoster = false
      }
    },
    sozlesmeKey(val) {
      this.seciliSozlesmeler.length > 0 ? this.sozlesmeTarihBaslikGoster = true : this.sozlesmeTarihBaslikGoster = false
    },
    kontrol(newVal) {
      if (newVal) {
        this.cakismaKontrol();
      }
    },
  },
  computed: {
    tatilList() {
      return this.$store.getters.getTatilList
    },
    pickerOptions() {
      const self = this
      return {
        firstDayOfWeek: 1,
        disabledDate(time) {
          const dateList = []
          self.tatilList.forEach(item => dateList.push(new Date(item.baslamaTarihi).toString()))
          const veri = dateList.find(item => item == time)
          return veri
        }
      }
    },
  },
  created() {
    this.checkDevice();
  },
  mounted() {
    window.addEventListener('resize', this.checkDevice);
    this.getMusteriListesi();
    this.getUlkeSehirListesi();
    this.getSozlesmeListesi();
    this.sozlesmeList.forEach(el => {
      el.secili = false;
      el.baslamaTarihi = '';
      el.isTeslimTarihi = '';
    })
    this.sozlesmeKey++;
    const self = this;
    this.disabledDatesFilter()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice);
  },
  data() {
    return {
      disabledTarihler: [],
      isDesktop: false,
      grupSwitchState: false,
      dialogVisibleYonetici: false,
      secim: false,
      kontrol: false,
      musteriler: [],
      ulkeler: [],
      ulkeSehir: [],
      iller: [],
      ilceler: [],
      sozlesmeList: [],
      etut: [],
      gruplanmisSozlesmeler: [],
      sozlesme: "",
      baslamaTarihi: "",
      bitisTarisi: "",
      tumunuSec: false,
      sozlesmeKey: 0,
      cakisma: "",
      cakismaIDs: [],
      allSubArraysEmpty: false,
      loading: false,
      ilLoading: false,
      ilceLoading: false,
      selectedImagesFromServer: [],
      path: haberService.path,
      imagePath: haberService.imagePath,
      belgePath: haberService.belgePath,
      editor: ClassicEditor,
      editorConfig: {
        language: 'tr',
      },
      activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
      visibleSil: false,
      disabled: false,
      options: [],
      dateRangeOptions: {
        firstDayOfWeek: 1
      },
      seciliSozlesmeler: [],
      sozlesmeTarihBaslikGoster: false,
      etutForm: {
        tur: 20,
        kategori: '',
        musteriAdi: '',
        projeBaslik: '',
        alan: '',
        ada: '',
        parsel: '',
        musteriID: '',
        ulkeID: '',
        ilID: '',
        ilceID: '',
        adresBaslik: 'OSB',
        baslangicTarihi: time,
        bitisTarihi: time,
        durum: '1',
        etutAtamalari: [],
        yuzdeDeger: '',
        baslamaSuresi: '',
        isSuresi: '',
        baslamaTarihi: '',
        tamamlanmaTarihi: ''
      },
      selectIcon: '1',
      page: 0,
      postPage: 0,
      sizes: 10,
      rulesEtut: {
        projeBaslik: [{
          required: true,
          message: "Lütfen Proje Adı Giriniz",
          trigger: 'blur'
        }],
        musteriID: [{
          required: true,
          message: "Lütfen Firma Seçiniz",
          trigger: 'blur'
        }],
        alan: [{
          required: true,
          message: "Lütfen Alan Giriniz",
          trigger: 'blur'
        }],
        ada: [{
          required: true,
          message: "Lütfen Ada Giriniz",
          trigger: 'blur'
        }],
        parsel: [{
          required: true,
          message: "Lütfen Parsel Giriniz",
          trigger: 'blur'
        }],
        adresBaslik: [{
          required: true,
          message: "Lütfen Adres Giriniz",
          trigger: 'blur'
        }],
        ulkeID: [{
          required: true,
          message: "Lütfen Ülke Seçiniz",
          trigger: 'blur'
        }],
        ilID: [{
          required: true,
          message: "Lütfen İl Seçiniz",
          trigger: 'blur'
        }],
        ilceID: [{
          required: true,
          message: "Lütfen İlçe Seçiniz",
          trigger: 'blur'
        }],
        baslangicTarihi: [{
          required: true,
          message: "Lütfen Tarih Seçiniz",
          trigger: 'blur'
        }],
        bitisTarihi: [{
          required: true,
          message: "Lütfen Tarih Seçiniz",
          trigger: 'blur'
        }],
      },
    }
  },
  methods: {
    disabledDatesFilter() {
      var araTarih = function (startDate, endDate) {
        var now = startDate.clone(), dates = [];
        while (now.isSameOrBefore(endDate)) {
          dates.push(now.format('YYYY-MM-DD'));
          now.add(1, 'days');
        }
        return dates;
      };
      this.tatilList.forEach(item => araTarih(moment(item.baslamaTarihi), moment(item.bitisTarihi)).forEach(veri => this.disabledTarihler.push(veri)))
    },
    datePickerOptions(baslamaTarihi) {
      const self = this
      return {
        firstDayOfWeek: 1,
        disabledDate: date => {
          const disabledTarihler = self.disabledTarihler.find(item => moment(item).format("MMM Do YY") == moment(date).format("MMM Do YY"))
          return date < new Date(baslamaTarihi) || disabledTarihler
        }
      };
    },
    sozlesmeDatePickerOptions(baslamaTarihi) {
      const self = this
      return {
        firstDayOfWeek: 1,
        disabledDate: date => {
          const disabledTarihler = self.disabledTarihler.find(item => moment(item).format("MMM Do YY") == moment(date).format("MMM Do YY"))
          if(baslamaTarihi != undefined) return date < new Date(baslamaTarihi) || disabledTarihler
          else return date < new Date(this.etutForm.baslangicTarihi) || disabledTarihler
        }
      };
    },
    cakismaKontrol() {
      const sozlesmeCakismalari = this.seciliSozlesmeler.map(item => ({
        sozlesmeID: item.sozlesmeID,
        baslamaTarihi: item.baslamaTarihi,
        tamamlanmaTarihi: item.isTeslimTarihi
      }));
      sozlesmeService.kesisenSozlesmeler2(JSON.stringify(sozlesmeCakismalari)).then((response) => {
        localStorage.setItem("userDataBGSurec", response.token)
        if (response.status == 200) {
          this.cakisma = response.data;
          this.allSubArraysEmpty = !(this.cakisma.every(subArray => subArray.length === 0));
          // console.log("-----")
          // console.log(this.cakisma)
          this.cakisma.forEach(subArray => {
            if (Array.isArray(subArray) && subArray.length > 0) {
              subArray.forEach(item => {
                this.cakismaIDs.push(item.sozlesmeID);
              });
            }
          });
        }
      })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.kontrol = false;
          });
    },
    mobileCheckClick(sozlesme, value) {
      if (value == 1) {
        this.seciliSozlesmeler.push(sozlesme)
        if (this.seciliSozlesmeler.length === this.gruplanmisSozlesmeler[0].sozlesmeler.length) this.tumunuSec = true
      } else {
        const index = this.seciliSozlesmeler.findIndex((item) => item.sozlesmeID === sozlesme.sozlesmeID);
        this.seciliSozlesmeler.splice(index, 1)
        if (this.seciliSozlesmeler.length !== this.gruplanmisSozlesmeler[0].sozlesmeler.length) this.tumunuSec = false
      }
    },
    formatYuzdeDeger(yuzdeDeger) {
      return yuzdeDeger.replace(/\D/g, "").replace(/\B(?=(\d{2})+(?!\d))/g, ".");
    },
    validateParsel() {
      const regex = /^[0-9!@#\$%\^\&*\)\(+=._-]+$/;

      if (!regex.test(this.etutForm.parsel)) {
        this.etutForm.parsel = this.etutForm.parsel.replace(/[^0-9!@#\$%\^\&*/\)\(+=._-]+$/, '');
      }
    },
    formatAlan() {
      this.etutForm.alan = this.etutForm.alan.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    checkDevice() {
      this.isDesktop = window.innerWidth >= 821;
    },
    // datePickerOptions(baslamaTarihi) {
    //   return {
    //     firstDayOfWeek: 1,
    //     disabledDate: date => {
    //       // Başlama tarihinden önceki tarihler seçilemez
    //       return date < new Date(baslamaTarihi);
    //     }
    //   };
    // },
    sozlesmeSec(item) {
      let total = parseInt(item.baslangicSuresi) + parseInt(item.isSuresi)
      let baslama = moment(this.etutForm.baslangicTarihi).add(item.baslangicSuresi, 'days')
      item.baslamaTarihi = moment(baslama).format('YYYY-MM-DD')
      this.isSuresi(item, total)
      this.sozlesme = item.sozlesmeID
      this.baslamaTarihi = item.baslamaTarihi
      this.bitisTarihi = item.isTeslimTarihi
      this.kontrol = true
    },
    open(sozlesmeID) {
      const foundSubArray = this.cakisma.find(subArray => {
        return subArray.some(item => item.sozlesmeID === sozlesmeID);
      });
      if (foundSubArray) {
        this.userDataBGSurec = foundSubArray;
        this.dialogVisibleYonetici = true;
      }
    },
    isSuresi(item, total) {
      let bitis = moment(this.etutForm.baslangicTarihi).add(total, 'days')
      item.isTeslimTarihi = moment(bitis).format('YYYY-MM-DD')
    },
    filterMethod(query, item) {
      return item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    },
    refreshPage() {
      this.page = 1;
      this.postPage = 0;
      this.loading = false;
      this.stateLoading = false;

      // this.getMusteriSayisi();
      this.getMusteriListesi();
      //this.getUlkeListesi();
      this.getSozlesmeListesi();
      // this.getIlListesi();
    },
    getMusteriListesi() {
      try {
        this.loading = true;
        musteriService.musteriListesi(this.selectIcon, this.postPage, 1000).then((response) => {
          localStorage.setItem("userDataBGSurec", response.token)
          if (response.status == 200) {
            this.musteriler = response.data;
          }
          this.loading = false;
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.musteriler = [];
            } else notification.Status("errorMsg", this, error.errMsg)
          } else {
            //notification.Status(503, this)
          }
          this.loading = false;
        })
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false
      }
    },
    getUlkeListesi() {
      try {
        this.loading = true;
        projeService.ulkeGetir().then((response) => {
          localStorage.setItem("userDataBGSurec", response.token)
          if (response.status == 200) {
            this.ulkeler = response.data;
          }
          this.loading = false;
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.ulkeler = [];
            } else notification.Status("errorMsg", this, error.errMsg)
          } else {
            //notification.Status(503, this)
          }
          this.loading = false;
        })
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false
      }
    },
    getUlkeSehirListesi() {
      try {
        this.loading = true;
        //this.etutForm.ulkeID = ""
        projeService.ulkeSehirListele().then((response) => {
          localStorage.setItem("userDataBGSurec", response.token)
          if (response.status == 200) {
            this.ulkeSehir = response.data;
          }
          this.loading = false;
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.ulkeSehir = [];
            } else notification.Status("errorMsg", this, error.errMsg)
          } else {
            //notification.Status(503, this)
          }
          this.loading = false;
        })
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false
      }
    },
    getIlListesi() {
      try {
        this.etutForm.ilID = ""
        this.etutForm.ilceID = ""
        const il = this.ulkeSehir.find((ulke) => ulke.ulkeID == this.etutForm.ulkeID)
        this.iller = il.il
      } catch (e) {
      }
    },
    getIlceListesi() {
      try {
        this.etutForm.ilceID = ""
        const ilce = this.iller.find((il) => il.ilID == this.etutForm.ilID)
        this.ilceler = ilce.ilce
      } catch (e) {
      }
    },
    getSozlesmeListesi() {
      try {
        this.loading = true;
        sozlesmeService.sozlesmeListesi(this.selectIcon, this.postPage, "").then((response) => {
          localStorage.setItem("userDataBGSurec", response.token)
          if (response.status == 200) {
            this.total = parseInt(response.data.sozlesmeSayisi);
            this.sozlesmeList = response.data;
            this.etut = this.sozlesmeList.find(el => el.sozlesmeID == 1)
            this.total = parseInt(response.count);
            const gruplanmisSozlesmeler = [];
            const sozlesmeListesi = [];
            this.sozlesmeList.forEach((sozlesme) => {
              if (sozlesme.sozlesmeKategoriAdi === 'BG Proje') {
                sozlesmeListesi.push(sozlesme)
              }
            });
            gruplanmisSozlesmeler.push({
              sozlesmeKategoriAdi: sozlesmeListesi[0].sozlesmeKategoriAdi,
              sozlesmeKategoriID: sozlesmeListesi[0].sozlesmeKategoriID,
              sozlesmeler: [...sozlesmeListesi]
            })
            this.gruplanmisSozlesmeler = gruplanmisSozlesmeler
          }
          this.loading = false;
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.sozlesmeList = [];
            } else notification.Status("errorMsg", this, error.errMsg)
          } else {
            //notification.Status(503, this)
          }
          this.loading = false;
        })
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false
      }
    },
    grupTumunuSec(grup, event) {
      if (event == 1) {
        grup.sozlesmeler.forEach(item => {
          item.secili = "1"
          this.seciliSozlesmeler.push(item)
        });
      } else {
        grup.sozlesmeler.forEach(item => {
          item.secili = "0"
          this.seciliSozlesmeler = []
        });
      }
    },
    atama(sozlesme, secili) {
      const index = this.seciliSozlesmeler.findIndex((item) => item.sozlesmeID === sozlesme.sozlesmeID);
      if (index === -1) {
        this.seciliSozlesmeler.push(sozlesme)
        if (this.seciliSozlesmeler.length === this.gruplanmisSozlesmeler[0].sozlesmeler.length) this.gruplanmisSozlesmeler[0].grupSwitchState = '1'
      } else {
        this.seciliSozlesmeler.splice(index, 1);
        if (this.seciliSozlesmeler.length !== this.gruplanmisSozlesmeler[0].sozlesmeler.length) this.gruplanmisSozlesmeler[0].grupSwitchState = '0'
      }
      let isShow = false
      const durumIndex = this.gruplanmisSozlesmeler[0].sozlesmeler.findIndex(item => item.durum == 10)
      if (durumIndex !== -1) isShow = true, this.gruplanmisSozlesmeler[0].grupSwitchState = '1'
      else isShow = false, this.gruplanmisSozlesmeler[0].grupSwitchState = '2'
      this.sozlesmeTarihBaslikGoster = isShow
    },
    etutEkle(formName) {
      const sozlesmeList = []
      this.gruplanmisSozlesmeler.forEach(item => {
        sozlesmeList.push(...item.sozlesmeler)
      })
      sozlesmeList.forEach(item => item.durum = item.secili)
      if (this.etutForm.baslangicTarihi > this.etutForm.bitisTarihi) {
        notification.Status("warning", this, "Etüt Teklifin Başlangıç Tarihi, Projenin Bitiş Tarihinden Önce Olamaz")
        this.etutForm.etutAtamalari = []
        this.loading = false;
        return;
      }
      if (this.etutForm.baslangicTarihi == this.etutForm.bitisTarihi) {
        notification.Status("warning", this, "Etüt Teklifin Başlangıç Tarihi ile Projenin Bitiş Tarihi Aynı Olamaz")
        this.etutForm.etutAtamalari = []
        this.loading = false;
        return;
      }
      const atanacaklar = JSON.parse(JSON.stringify(this.etut));
      let enSonTeslimTarihi = null;
      for (let i = 0; i < atanacaklar.length; i++) {
        if (atanacaklar[i].isTeslimTarihi > this.etutForm.bitisTarihi) {
          notification.Status("warning", this, "Etüt Teklifin Tamamlanma Tarihi, Proje Bitiş Tarihinden Sonra Olamaz");
          this.etutForm.etutAtamalari = []
          this.loading = false;
          return;
        }

        if (enSonTeslimTarihi === null || atanacaklar[i].isTeslimTarihi > enSonTeslimTarihi) {
          enSonTeslimTarihi = atanacaklar[i].isTeslimTarihi;
        }
      }
      this.$refs[formName].validate((valid, errors) => {
        if (valid) {
          this.$confirm("Etüt teklifi tanımlamak istediğinize emin misiniz?", this.$t("src.views.apps.genel.uyari"), {
            confirmButtonText: this.$t("src.views.apps.genel.yes"),
            cancelButtonText: this.$t("src.views.apps.genel.no"),
            type: 'warning'
          }).then(() => {
            try {
              this.loading = true;
              projeService.projeEkle(sozlesmeList, this.etutForm.musteriID, this.etutForm.projeBaslik, this.etutForm.adresBaslik, this.etutForm.ulkeID, this.etutForm.ilID, this.etutForm.ilceID, this.etutForm.alan, this.etutForm.ada, this.etutForm.parsel, this.etutForm.baslangicTarihi, this.etutForm.bitisTarihi, this.etutForm.tur, this.etutForm.durum).then((response) => {
                if (response.status == 200) {
                  localStorage.setItem("userDataBGSurec", response.token)
                  notification.Status("success", this, response.msg)
                  EventBus.$emit("etutlistesi", true)
                  functions.sayfaKapat("etutteklif-tanimla", this);
                  this.resetForm(formName)
                }
                this.loading = false;
              }).catch(err => {
                if (err.responseJSON) {
                  let error = err.responseJSON
                  if (error.status == 401) {
                    notification.Status(150, this);
                  } else notification.Status("errorMsg", this, error.errMsg)
                } else {
                  //notification.Status(503, this)
                }
                this.loading = false;
              })
            } catch (e) {
              // console.log(e);
              notification.Status("warning", this, this.$t('Etüt Teklif Tanımlanırken Bir Hata Meydana Geldi'))
              this.loading = false;
            }
          }).catch(() => {
            this.etutForm.etutAtamalari = []
          });
        } else {
          for (var k in errors) {
            errors[k].forEach((element) => {
              notification.Status("warning", this, element.message);
            })
            this.loading = false
          }
        }
      })
    },
    indexMethod(index) {
      return ((this.page - 1) * 10) + index + 1;
    },
    handleSizeChange(val) {
      this.sizes = val;
      this.getMusteriListesi();
      //this.getUlkeListesi();
      this.getSozlesmeListesi();
    },
    handleChange(event) {
      this.selectIcon = event;
      this.page = 1;
      this.postPage = 0;
      this.getMusteriListesi();
      // this.getUlkeListesi();
      this.getSozlesmeListesi();
      document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
    },
    pageChange(val) {
      this.postPage = (val - 1) * this.sizes;
      this.getMusteriListesi();
      // this.getUlkeListesi();
      this.getSozlesmeListesi();
    },
    vazgec(formName) {
      this.$refs[formName].resetFields();
      this.editDialog = false;
      this.loading = false;
      this.stateLoading = false;
    },
    tumunuSecChange(event) {
      this.tumunuSec = event
      if (!event) {
        this.seciliSozlesmeler = []
        Object.values(this.gruplanmisSozlesmeler[0].sozlesmeler).forEach(item => {
          item.secili = '0'
        })
        // gruplanmis secililer false olacak
      }
      if (event) {
        this.sozlesmeList.forEach(el => el.secili = this.tumunuSec)
        this.seciliSozlesmeler = this.gruplanmisSozlesmeler[0].sozlesmeler.filter(el => el.secili)
        // this.seciliSozlesmeler = this.sozlesmeList.filter(el => el.secili == this.tumunuSec)
        this.sozlesmeTarihBaslikGoster = this.tumunuSec
      }
      this.sozlesmeKey++
    },
    baslamaSuresiDegistir(event, item) {
      let secilenProjeTarihi = moment(this.etutForm.baslangicTarihi);
      let baslamaTarihi = moment(item.baslamaTarihi);
      var fark = Math.abs(baslamaTarihi.diff(secilenProjeTarihi, 'days'));
      //console.log("baslama suresi fark : ", fark)
      item.baslangicSuresi = fark.toString()
    },
    bitisSuresiDegistir(event, item) {
      let baslamaTarihi = moment(item.baslamaTarihi);
      //console.log(baslamaTarihi)
      let isTeslimTarihi = moment(item.isTeslimTarihi);
      //console.log(isTeslimTarihi)
      var fark = Math.abs(isTeslimTarihi.diff(baslamaTarihi, 'days'));
      //console.log(fark)
      //console.log("bitis suresi fark : ", fark)
      item.isSuresi = fark.toString()
    },
    seciliSozlesmeFiltre() {
      this.seciliSozlesmeler = [];
      //console.log(this.sozlesmeList)
      this.seciliSozlesmeler = this.sozlesmeList.filter(el => el.secili == true)

      //console.log(this.gruplanmisSozlesmeler)
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.visibleSil = false;
    },
  }
}

</script>
<style lang="scss">
@media screen and(max-width: 1200px) {
  .sozlesmeSelectEtut {
    .el-row {
      padding: 0 0 40px 0 !important;
    }
  }
}

.sozlesmeSelectEtut {
  .el-row {
    padding: 10px 10px 10px 8px;
  }

}

</style>
<style>
.flex-box {
  display: flex;
  justify-content: flex-end;
}

.kapsamlar .el-collapse-item .el-collapse-item__header {
  color: white;
  text-align: center;
  font-size: 15px !important;
  padding: 0px 10px;
  font-weight: 400;
  border-radius: 8px;
}

.kapsamlar .el-collapse-item:nth-child(1) .el-collapse-item__header {
  background-color: #28323b;
}

.kapsamlar .el-collapse-item:nth-child(2) .el-collapse-item__header {
  background-color: #006cae;
}

.kapsamlar .el-collapse-item:nth-child(3) .el-collapse-item__header {
  background-color: #3595cf;
}

.kapsamlar .el-collapse-item:nth-child(4) .el-collapse-item__header {
  background-color: #ff8b00;
}
</style>
