import WsProvider from './provider/WSProvider'


var serviceName = "webservis";
var moduleName = "etut";


var etutService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,
    projeListesi(durum, baslangic, limit) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            durum: durum,
            baslangic: baslangic,
            limit,
            serviceName: moduleName,
            methodName: "projeListesi"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    projeRapor() {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            serviceName: moduleName,
            methodName: "projeRapor"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    genelRapor() {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            serviceName: moduleName,
            methodName: "genelRapor"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    ulkeSehirListele() {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            serviceName: moduleName,
            methodName: "ulkeSehirListele"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    ulkeGetir() {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            serviceName: moduleName,
            methodName: "ulkeGetir"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    ilGetir(ulkeID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            ulkeID,
            serviceName: moduleName,
            methodName: "ilGetir"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    ilceGetir(ilID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            ilID,
            serviceName: moduleName,
            methodName: "ilceGetir"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    projeSozlesmeleri(projeID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            projeID,
            serviceName: moduleName,
            methodName: "projeSozlesmeleri"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    projeGrupluSozlesmeler(projeID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            projeID,
            serviceName: moduleName,
            methodName: "projeGrupluSozlesmeler"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    personelProjeSozlesmeleri(projeID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            projeID,
            serviceName: moduleName,
            methodName: "personelProjeSozlesmeleri"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    // etutDetay(projeID) {
    //     var userDataBGSurec = localStorage.getItem("userDataBGSurec");
    //     var param = {
    //         token: userDataBGSurec,
    //         projeID,
    //         serviceName: moduleName,
    //         methodName: "projeDetay"
    //     }
    //     var result = new Promise(function (resolve, reject) {
    //         WsProvider.ajaxPost(serviceName, param).then((response) => {
    //             resolve(response)
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    //     return result
    // },
    etutEkle(musteriID, projeBaslik, adresBaslik, ulkeID, ilID, ilceID, alan, ada, parsel, baslangicTarihi, bitisTarihi, durum, etutAtamalari, tur) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            musteriID: musteriID,
            projeBaslik: projeBaslik,
            adresBaslik: adresBaslik,
            ulkeID: ulkeID,
            ilID: ilID,
            ilceID: ilceID,
            alan: alan,
            ada: ada,
            parsel: parsel,
            baslangicTarihi: baslangicTarihi,
            bitisTarihi: bitisTarihi,
            durum: durum,
            etutAtamalari: etutAtamalari,
            tur: tur,
            methodName: "etutEkle",
            serviceName: moduleName
        }

        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },
    projeFilter(musteriAdi, projeBaslik, alan, ada, parsel, konum, baslangicTarihi, bitisTarihi, durum, baslangic, limit) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            musteriAdi,
            projeBaslik,
            alan,
            ada,
            parsel,
            konum,
            baslangicTarihi,
            bitisTarihi,
            durum,
            baslangic,
            limit,
            serviceName: moduleName,
            methodName: "projeArama"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    projeSayisi(durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            durum: durum,
            serviceName: moduleName,
            methodName: "projeSayisi"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    projeGuncelle1(projeID, updateList) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            projeID,
            updateList,
            serviceName: moduleName,
            methodName: "projeDuzenle"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    projeGuncelle(projeID, updateList, sozlesmeAtamalari) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            projeID,
            updateList,
            sozlesmeAtamalari,
            serviceName: moduleName,
            methodName: "projeDuzenle"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    projeSozlesmeKapsamDuzenle(projeAtamaID, updateList) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            projeAtamaID,
            updateList,
            serviceName: moduleName,
            methodName: "projeSozlesmeKapsamDuzenle"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
}

export default etutService